// Russian
export default{
    form: {
        startSurveyButton: "Начать",
        nextButton: "Далее",
        submitAnswerButton: "Отправить ответ",
        continueButton: "Продолжить",
        finishButton: "Завершить",
        headerPercentageCompletion: 'завершено',
        headerQuestionsCompleted: 'вопросов',
        headerDefaultErrorMessage: 'Упс. Что-то пошло не так.',
        brandingPoweredBy: 'Работает на',
        brandingPromoWhySurvey: 'Зачем oпрашивать, когда можно использовать <b>{product}</b>?',
        brandingPromoGetStarted: 'Начните сегодня!',
        submissionPending: "Отправка вашей формы. Пожалуйста, не закрывайте окно браузера.",
        submissionCanCloseWindow: "Форма успешно отправлена. Теперь можно закрыть окно 👍",
        mediaAttachmentOverlayCloseButton: "Закрыть",
        expiredFormHeader: "Спасибо, что зашли!",
        expiredFormMessage: "Похоже, этот опрос истек. Пожалуйста, свяжитесь с создателем формы.",
        notPublishedFormHeader: "Форма не опубликована!",
        notPublishedFormMessage: "Похоже, эта форма не опубликована. Пожалуйста, свяжитесь с её создателем.",
        notPublishedFormRetryButton: "Попробовать снова",
        errorFormHeader: "Ой!",
        errorFormMessage: "Возможно, что-то пошло не так. Попробуйте позже.",
        errorFormRetryButton: "Попробовать снова",
        emptyFormHeader: "Спасибо, что зашли!",
        emptyFormMessage: "Ой! Похоже, форма пуста и не содержит вопросов или полей. Приносим извинения за неудобства. Пожалуйста, свяжитесь с создателем формы, чтобы сообщить об этом. Спасибо за понимание!",

        restoreFormHeader: "Похоже, у вас есть незавершенная форма!",
        restoreFormMessage: "Хотите продолжить с того места, где остановились?",
        restoreFormButton: "Продолжить",
        restoreFormButtonNew: "Начать заново",

        audioPermissionHeader: "Хотите ответить голосом?",
        audioPermissionMessage: "Говорить — быстрее и проще.",
        audioPermissionAcceptButton: "Да, хочу",
        audioPermissionDenyButton: "Нет, спасибо",
        audioPermissionErrorHeader: "Микрофон недоступен",
        audioPermissionErrorMessage: "Проверьте настройки доступа к микрофону в браузере.",
        audioPermissionErrorButton: "ОК",
        audioRecorderFailedUpload: "Не удалось загрузить запись",
        silenceDetectorError: "Не слышно. Проверьте настройки микрофона. Возможно, используется внешний микрофон.",
        audioRecorderButtonRecord: "Записать",
        audioRecorderButtonRecordMore: "Записать еще",
        voiceResponseSelectionMessage: "Выберите, как хотите ответить...",
        voiceResponseSelectionOrDivider: "или",
        voiceResponseTextInputPlaceholder: "Введите ответ",
        voiceResponseTextInputCharacterCounterMinMessage: "Введите минимум {min} символов",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} символов (минимум {min} символов)",
        voiceResponseTextInputCharacterValidationMessage: "{count} символов (минимум {min} символов)",

        // checkbox
        checkboxValidationTooFew: "Выберите минимум {min} вариант(ов)",
        checkboxValidationTooMany: "Выберите не более {max} вариант(ов)",
        checkboxNoneOfTheAboveOption: "Ничего из вышеперечисленного",
        checkboxInvalidAnswer: "Неправильный ответ.",

        // datepicker
        datePickerPlaceholder: "Выберите дату",

        // dropdown
        // email
        emailLabel: "Электронная почта",
        emailInvalid:"Неверный адрес электронной почты.",
        // file-upload
        fileUploadPluginNameCamera: "Камера",
        fileUploadPluginNameCameraVideo: "Записать видео",
        fileUploadPluginScreenCast: "Запись экрана",
        fileUploadVideoImportFilesDefault: 'Запишите или загрузите видео через:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Выберите опцию записи видео:',
        fileUploadVideoImportFilesNoCamera: 'Нажмите для записи экрана',
        fileUploadVideoImportFilesNoScreenCast: 'Нажмите для записи видео',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Нет доступных опций для записи видео',

        // matrix
        matrixValidationMessage: "Ответ верен.",
        matrixRow: "Строка",
        matrixColumn: "Столбец",
        matrixRowRequired: "Строка {rowTitle} обязательна.",
        matrixRadioGroup: "Радио-группа",
        matrixCheckboxGroup: "Группа флажков",
        matrixGroupRequired: "Для строки {rowTitle}. {type} {groupTitle} обязателен.",
        matrixColumnRequired: "Для строки {rowTitle}. Столбец {columnTitle} обязателен.",
        matrixColumnInvalid: "Для строки {rowTitle}. Столбец {columnTitle} неверен.",
        matrixRequired: "Обязательно.",
        matrixNumericMustBeNumber: "Должно быть числом.",
        matrixNumericMustBeGreaterThenMin: "Должно быть больше {min}.",
        matrixNumericMustBeLessThenMax: "Должно быть меньше {max}.",
        matrixNumericMustBeInteger: "Должно быть целым числом.",
        matrixNumericInvalidNumber: "Неверное число.",

        // name
        nameLabel: "Имя",
        nameInvalid: "Укажите имя и фамилию.",

        // nps
        npsNotLikely: "Совсем не вероятно",
        npsExtremelyLikely: "Очень вероятно",

        // numeric input
        numericInputRequired: "Обязательно.",
        numericInputMustBeNumber: "Должно быть числом.",
        numericInputMustBeGreaterThenMin: "Должно быть больше {min}.",
        numericInputMustBeLessThenMax: "Должно быть меньше {max}.",
        numericInputMustBeInteger: "Должно быть целым числом.",
        numericInputInvalidNumber: "Неверное число.",
        numericInputPlaceholder: "Введите число",

        // phone
        phoneInvalid: "Неверный номер телефона.",
        phoneCountrySelectorLabel: 'Код страны',
        phoneCountrySelectorError: 'Выберите страну',
        phoneNumberLabel: 'Номер телефона',
        phoneExample: 'Пример:',

        // boolean
        booleanYesLabel: "Да",
        booleanNoLabel: "Нет",

        //questionStep
        questionStepAudioQuestionLabel: "Аудио вопрос",

        // errors
        invalidPhoneNumber: "{phone} — неверный номер телефона.",
        invalidEmail: "{email} — неверный адрес электронной почты.",
        questionIsRequired: "Вопрос обязателен.",
        answerIsNotValid: "Ответ неверен.",
        unfinishedProbingDialogError: "Пожалуйста, завершите диалог.",
        cannotResumePartialResponse: "Невозможно продолжить неполный ответ.",
        failedToSubmitForm: "Не удалось отправить форму. Проверьте подключение к интернету и попробуйте снова."
    }
}
